.corporate-link-section {
  width: 100%;
  max-width: 420px;
  border: 1px solid var(--border-color);
  border-radius: 9px;
  padding: 32px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px 16px;
}

.corporate-link-section h3 {
  text-align: center;
  font-size: 22px;
  color: var(--theme-dark-color);
  margin-bottom: 32px;
}

.corporate-link-section .separator {
  position: initial;
  margin: 0 16px;
}

.corporate-link-section .corporate-link-note {
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: var(--main-text-color);
}

.corporate-profile-details {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.corporate-profile-details .corporate-block {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 40%;
  align-items: center;
  text-align: center;
}

.corporate-profile-details .corporate-block:nth-child(2) {
  width: 20%;
  margin-top: 8%;
  max-width: 36px;
}

.corporate-block .corporate-avatar {
  border-radius: 50%;
  overflow: hidden;
  max-width: 80px;
  max-height: 80px;
  border: 1px solid var(--border-color);
}

.corporate-block .corporate-avatar img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1 /1;
  object-fit: cover;
  object-position: center center;
}

.corporate-block > p {
  font-weight: 600;
  color: var(--main-text-color);
  font-size: 14px;
}
